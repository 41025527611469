<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Businesses', route: 'Admin_Businesses' },
        { name: 'Create a Business', route: 'Admin_Businesses' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="warning" v-for="warning in warnings" :key="warning">
            {{ warning }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="CreateBusiness">
            <label for="form.name">Business / Practice Name</label>
            <input type="text" class="input" v-model="form.name" required />

            <label for="country">Country</label>
            <select name="country" v-model="form.country" class="input" @change="onCountryChange" required>
              <option value="">-- Please Choose a Country --</option>
              <option value="Belgium">Belgium</option>
              <option value="England, UK">England, UK</option>
              <option value="France">France</option>
              <option value="Germany">Germany</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Northern Ireland, UK">Northern Ireland, UK</option>
              <option value="Republic of Ireland">Republic of Ireland</option>
              <option value="Romania">Romania</option>
              <option value="Scotland, UK">Scotland, UK</option>
              <option value="Wales, UK">Wales, UK</option>
            </select>

            <label for="form.postcode">Postcode</label>
            <input
              type="text"
              class="input"
              v-model="form.postcode"
              @input="onPostcodeInput"
              required
            />

            <label for="form.address">Address</label>
            <textarea
              class="input left"
              v-model="form.address"
              @input="onAddressInput"
              @focus="showSuggestions = true"
              @blur="hideSuggestions"
              rows="4"
              required
            ></textarea>
            <ul v-if="showSuggestions && suggestions.length > 0" class="suggestions">
              <li v-for="suggestion in suggestions" :key="suggestion.id" @mousedown.prevent="selectSuggestion(suggestion)">
                {{ suggestion.summaryline }}, {{ suggestion.locationsummary }}
              </li>
            </ul>

            <label for="form.contact_email">Contact Email</label>
            <input type="email" class="input" v-model="form.contact_email" required />

            <label for="form.contact_telephone">Contact Telephone</label>
            <input
              type="tel"
              class="input"
              v-model="form.contact_telephone"
              required
            />

            <label for="form.infinite_credits">Unlimited Test Credits</label>
            <select
              name="form.infinite_credits"
              id="form.infinite_credits"
              class="input"
              v-model="form.infinite_credits"
              @change="toggleTestCreditsFields"
            >
              <option value="false">No</option>
              <option value="true">***Yes***</option>
            </select>

            <div v-if="form.infinite_credits === 'true'">
              <label for="form.activationdate">Test Credits Activation Date</label>
              <input
                type="date"
                class="input"
                v-model="form.activationdate"
                placeholder="Activation Date"
                @change="updateExpiryDate"
                required
              />

              <label for="form.expirydate">Test Credits Expiry Date</label>
              <input
                type="date"
                class="input"
                v-model="form.expirydate"
                placeholder="Expiry date"
                required
              />
            </div>

            <div v-if="form.infinite_credits === 'false'">
              <label for="form.credits">Test Credits</label>
              <input
                type="number"
                class="input"
                v-model="form.credits"
                min="0"
                required
              />
            </div>

            <label for="form.owner">OWNER Email Address</label>
            <input type="email" class="input" v-model="form.owner" required />
            <label for="form.mkit">MicronKit Number</label>
            <input
              type="character"
              class="input"
              v-model="form.mkit"        
              required      
            />
            <br />
            <br />
            <label for="form.fluke_kit">Fluke Kit Purchased ?</label>
            <select
                name="form.fluke_kit"
                id="form.fluke_kit"
                class="input"
                v-model="form.fluke_kit"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
            
            <div v-if="form.fluke_kit === 'true'">
              <label for="form.flukekitnumber">Fluke Kit Number</label>
              <input
                type="text"
                class="input"
                v-model="form.flukekitnumber"
                required
              />
            </div>
            <label for="form.businessstatus">Business Status</label>
            <select
              name="form.businessstatus"
              id="form.businessstatus"
              class="input"
              v-model="form.businessstatus"
            >
              <option value="Active">Active</option>
              <option value="Archived">Archived</option>
              <option value="Inactive">Inactive</option>
            </select>

            <br />
            <br />

            <label for="form.businesstype">Business Type</label>
            <select
              name="form.businesstype"
              id="form.businesstype"
              class="input"
              v-model="form.businesstype"
            >
              <option value="Vetstore">VET/ Farm Store</option>
              <option value="Equine">Equine</option>

            </select>


            <input type="submit" class="btn btn-green" value="Save Changes" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      messages: "",
      errors: "",
      warnings: "",
      showSuggestions: false,
      suggestions: [],
      form: {
        name: "",
        country: "",
        postcode: "",
        address: "",
        contact_email: "",
        contact_telephone: "",
        owner: "",
        mkit: "",
        fluke_kit: "false",
        flukekitnumber: "",
        infinite_credits: "false",
        activationdate: "",
        expirydate: "",
        credits: "",
        savedCredits: "",  
        savedActivationDate: "", 
        savedExpiryDate: "",  
        businessstatus:"Active",
        businesstype:"Vetstore"
      },
    };
  },
  components: {
    Navigation,
  },
  methods: {
    CreateBusiness() {      
      const dataToSubmit = {
        name: this.form.name,
        address: this.form.address,
        postcode: this.form.postcode,
        country: this.form.country,
        contact_email: this.form.contact_email,
        contact_telephone: this.form.contact_telephone,
        owner: this.form.owner,
        mkit: this.form.mkit,
        fluke_kit: this.form.fluke_kit,
        flukekitnumber: this.form.fluke_kit === "true" ? this.form.flukekitnumber : null,
        infinite_credits: this.form.infinite_credits,
        credits: this.form.infinite_credits === "false" ? this.form.credits : null,
        activationdate: this.form.infinite_credits === "true" ? this.form.activationdate : null,
        expirydate: this.form.infinite_credits === "true" ? this.form.expirydate : null,
        status : this.form.businessstatus,
        type:this.form.businesstype
      };

      this.store
        .dispatch("Admin/CreateBusiness", dataToSubmit)
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
          this.$router.push({
            name: "Admin_Businesses_Modify",
            params: { id: result.id },
          });
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    onCountryChange() {
      if (this.form.country && this.form.postcode) {                
        this.onPostcodeInput(); 
      } else if (this.form.country && this.form.address) {
        this.onAddressInput(); 
      }
    },
    onPostcodeInput() {      
      if (this.form.postcode && !this.form.country) {        
        this.warnings = ["Please select a country to lookup the address automatically."];
      } else if (this.form.country && this.form.postcode) {        
        this.warnings = [];
        const country = this.form.country;
        const postcode = this.form.postcode.replace(/\s+/g, "");

        const postcodeLengths = {
          "England, UK": [5, 6, 7, 8],
          "Northern Ireland, UK": [5, 6, 7, 8],
          "Scotland, UK": [5, 6, 7, 8],
          "Wales, UK": [5, 6, 7, 8],
          "New Zealand": [4],
          "Belgium": [4],
          "France": [5],
          "Luxembourg": [4],
          "Netherlands": [6],
          "Republic of Ireland": [7],
          "Romania": [6],
          "Germany": [5],
        };

        const validLengths = postcodeLengths[country];
        if (validLengths && validLengths.includes(postcode.length)) {
          this.lookupAddress(postcode, country);
        }
      }
    },
    onAddressInput() {      
      if (this.form.address.length >= 3 && !this.form.country) {
        this.warnings = ["Please select a country to autocomplete the address."];
      } else if (this.form.address.length >= 3 && this.form.country) {
        this.warnings = [];
        this.autocompleteAddress(this.form.address, this.form.country);
      } else {
        this.suggestions = [];
      }
    },
    autocompleteAddress(input, country) {
      this.store
        .dispatch("Admin/AutocompleteAddress", { input, country })
        .then((response) => {
          this.suggestions = response;
        })
        .catch((error) => {
          console.error("Error fetching autocomplete suggestions:", error);
          this.suggestions = [];
        });
    },
    selectSuggestion(suggestion) {
      this.form.address = suggestion.summaryline;
      this.suggestions = [];
      this.retrieveFullAddress(suggestion.id, suggestion.summaryline, this.form.country);
    },
    lookupAddress(postcode, country) {
      this.store
        .dispatch("Admin/LookupAddressByPostcode", { postcode, country })
        .then((response) => {
          const addressData = response.data;
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address = `${addressData[0].addressline1}\n${addressData[0].addressline2}`;
          } else {
            this.warnings = ["No address found for the given postcode. Please enter the address manually."];
            this.form.address = ""; 
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
          this.warnings = ["Error fetching address. Please enter the address manually."];
          this.form.address = ""; 
        });
    },
    retrieveFullAddress(id, input, country) {
      this.store
        .dispatch("Admin/RetrieveFullAddress", { id, input, country })
        .then((response) => {
          const addressData = response;
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address = `${addressData[0].addressline1}\n${addressData[0].addressline2}`;
            this.form.postcode = addressData[0].postcode; // Update the postcode field
          } else {
            this.warnings = ["No full address found for the selected suggestion."];
            this.form.address = "";
          }
        })
        .catch((error) => {
          console.error("Error retrieving full address:", error);
          this.warnings = ["Error retrieving full address. Please enter the address manually."];
          this.form.address = "";
        });
    },
    hideSuggestions() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },
    updateExpiryDate() {
      if (this.form.activationdate) {
        const activationDate = new Date(this.form.activationdate);
        const expiryDate = new Date(activationDate.getFullYear() + 1, activationDate.getMonth(), activationDate.getDate());
        this.form.expirydate = expiryDate.toISOString().slice(0, 10);
      }
    },
    toggleTestCreditsFields() {
      if (this.form.infinite_credits === "true") {        
        this.form.savedCredits = this.form.credits;
        this.form.credits = "";
      } else {        
        this.form.credits = this.form.savedCredits;
      }

      if (this.form.infinite_credits === "false") {        
        this.form.savedActivationDate = this.form.activationdate;
        this.form.savedExpiryDate = this.form.expirydate;
        this.form.activationdate = "";
        this.form.expirydate = "";
      } else {        
        this.form.activationdate = this.form.savedActivationDate;
        this.form.expirydate = this.form.savedExpiryDate;
      }
    },
  },
};
</script>

<style>
.warning {
  height: auto;
  width: 100%;
  background: #eb8146;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #ffb248;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}
</style>
