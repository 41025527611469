<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Users', route: 'Admin_Users' },
        { name: 'Create a User', route: 'Admin_Users' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <form v-on:submit.prevent="CreateUser" class="form">
          <div class="col-6">
            <div class="error" v-for="error in errMsg" :key="error">
              {{ error }}
            </div>
            <div class="warning" v-for="warning in warnings" :key="warning">
              {{ warning }}
            </div>
            <div class="success" v-for="message in msg" :key="message">
              {{ message }}
            </div>
            <h3 class="green">Users Personal Information</h3>
            <label for="name">Full Name</label>
            <input
              type="text"
              class="input"
              v-model="form.name"
              placeholder="Example Person"
              required
            />

            <label for="email">Email Address</label>
            <input
              type="email"
              class="input"
              v-model="form.email"
              placeholder="example@example.com"
              required
            />

            <label for="password">Password</label>
            <input
              type="text"
              class="input"
              v-model="form.password"
              placeholder="Password"
              required
            />

            <label for="available_test">Test Credits</label>
            <input
              type="number"
              class="input"
              v-model="form.available_test"
              placeholder="250"
              min="0"
              required
            />
            <br /><br />
            <label for="phone">Phone</label>
            <input
              type="tel"
              class="input"
              v-model="form.phone"
              placeholder="Phone"
            />

            <label for="birthday">Date of Birth</label>
            <input
              type="date"
              class="input"
              v-model="form.birthday"
              placeholder="Date of Birth"
            />
          </div>
          <div class="col-6">
            <h3 class="green">Users Address Information</h3>

            <label for="country">Country</label>
            <select name="country" v-model="form.country" class="input" @change="onCountryChange">
              <option value="N/A">-- Please Choose a Country --</option>
              <option value="Belgium">Belgium</option> 
              <option value="England, UK">England, UK</option> 
              <option value="France">France</option> 
              <option value="Germany">Germany</option>
              <option value="Luxembourg">Luxembourg</option> 
              <option value="Netherlands">Netherlands</option> 
              <option value="Northern Ireland, UK">Northern Ireland, UK</option> 
              <option value="Republic of Ireland">Republic of Ireland</option>
              <option value="Romania">Romania</option>
              <option value="Scotland, UK">Scotland, UK</option> 
              <option value="Wales, UK">Wales, UK</option>          
            </select>

            <label for="post_code">Post/Zip Code</label>
            <input
              type="text"
              class="input"
              v-model="form.post_code"
              @input="onPostcodeInput"
              placeholder="Post Code"
              style="text-transform: uppercase"
            />

            <label for="address_line_1">First Line Address</label>
            <input
              type="text"
              class="input"
              v-model="form.address_line_1"
              @input="onAddressInput"
              @focus="showSuggestions = true"
              @blur="hideSuggestions"
              placeholder="First Line of Address"
            />
            <ul v-if="showSuggestions && suggestions.length > 0" class="suggestions">
              <li v-for="suggestion in suggestions" :key="suggestion.id" @mousedown.prevent="selectSuggestion(suggestion)">
                {{ suggestion.summaryline }}, {{ suggestion.locationsummary }}
              </li>
            </ul>

            <label for="address_line_2">Second Line Address</label>
            <input
              type="text"
              class="input"
              v-model="form.address_line_2"
              placeholder="Second Line of Address"
            />

            <label for="city">City</label>
            <input
              type="text"
              class="input"
              v-model="form.city"
              placeholder="City"
            />           
            
            <br />
            <br />
            <input
              id="is_active"
              type="checkbox"
              v-model="form.is_active"
              true-value="true"
              false-value="false"
            />
            <label style="display: inline" for="is_active">Is Active</label>
            <br />
            <br />
            <input
              id="is_email_confirmed"
              type="checkbox"
              v-model="form.is_email_confirmed"
              true-value="true"
              false-value="false"
            />
            <label style="display: inline" for="is_email_confirmed"
              >Email Confirmed</label
            >
            <br />
            <br />
            <input
              id="is_staff"
              type="checkbox"
              v-model="form.is_staff"
              true-value="true"
              false-value="false"
            />
            <label style="display: inline" for="is_staff">Staff</label>
            <br />
            <br />

            <input
              id="is_qc"
              type="checkbox"
              v-model="form.is_qc"
              true-value="true"
              false-value="false"
            />

            <label style="display: inline" for="is_qc">Quality checker</label>
            <br />
            <br />

            <input
              id="is_tester"
              type="checkbox"
              v-model="form.is_tester"
              true-value="true"
              false-value="false"
            />

            <label style="display: inline" for="is_tester">Tester</label>
            <br />
            <br />

            <input
              type="submit"
              class="btn btn-green"
              style="width: 100%"
              value="Create New User"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      errMsg: "",
      msg: "",
      warnings: [],
      suggestions: [],
      showSuggestions: false,
      form: {
        name: "",
        email: "",
        password: "",
        phone: "",
        birthday: "",
        avatar: "",
        address_line_1: "",
        address_line_2: "",
        post_code: "",
        city: "",
        country: "N/A",
        available_test: 0,
        is_active: true,
        is_staff: false,
        is_email_confirmed: false,
        is_qc: false,
        is_tester: false
      },
      countryOptions: [ 
        "Belgium",
        "England, UK",
        "France",
        "Germany",
        "Luxembourg",
        "Netherlands",
        "Northern Ireland, UK",
        "Republic of Ireland",
        "Romania",
        "Scotland, UK",
        "Wales, UK",
      ],
    };
  },
  components: {
    Navigation,
  },
  methods: {
    CreateUser() {
      this.store
        .dispatch("Admin/CreateNewUser", this.form)
        .then((result) => {
          console.log(result);
          this.msg = result.messages;
          this.errMsg = "";
        })
        .catch((err) => {
          this.errMsg = err.messages;
          this.msg = "";
          console.log(err);
        });
    },
    onCountryChange() {
      if (this.form.country !== "N/A" && this.form.post_code) {                
        this.onPostcodeInput(); 
      } else if (this.form.country !== "N/A" && this.form.address_line_1) {
        this.onAddressInput(); 
      }
    },
    onPostcodeInput() {
      if (this.form.post_code && this.form.country === "N/A") {
        this.warnings = ["Please select a country to lookup the address automatically."];
      } else if (this.form.country !== "N/A" && this.form.post_code) {
        this.warnings = [];
        const country = this.form.country;
        const post_code = this.form.post_code.replace(/\s+/g, "");

        const postcodeLengths = {
          "England, UK": [5, 6, 7, 8],
          "Northern Ireland, UK": [5, 6, 7, 8],
          "Scotland, UK": [5, 6, 7, 8],
          "Wales, UK": [5, 6, 7, 8],
          "Belgium": [4],
          "France": [5],
          "Luxembourg": [4],
          "Netherlands": [6],
          "Republic of Ireland": [7],
          "Romania": [6],
          "Germany": [5],
        };

        const validLengths = postcodeLengths[country];
        if (validLengths && validLengths.includes(post_code.length)) {
          this.lookupAddress(post_code, country);
        }
      }
    },
    onAddressInput() {
      if (this.form.address_line_1.length >= 3 && this.form.country === "N/A") {
        this.warnings = ["Please select a country to autocomplete the address."];
      } else if (this.form.address_line_1.length >= 3 && this.form.country !== "N/A") {
        this.warnings = [];
        this.autocompleteAddress(this.form.address_line_1, this.form.country);
      } else {
        this.suggestions = [];
      }
    },
    autocompleteAddress(input, country) {
      this.store
        .dispatch("Admin/AutocompleteAddress", { input, country })
        .then((response) => {
          this.suggestions = response;
        })
        .catch((error) => {
          console.error("Error fetching autocomplete suggestions:", error);
          this.suggestions = [];
        });
    },
    selectSuggestion(suggestion) {
      this.form.address_line_1 = suggestion.summaryline;
      this.suggestions = [];
      this.retrieveFullAddress(suggestion.id, suggestion.summaryline, this.form.country);
    },
    lookupAddress(post_code, country) {
      this.store
        .dispatch("Admin/LookupAddressByPostcode", { postcode: post_code, country })
        .then((response) => {
          const addressData = response.data;
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address_line_1 = `${addressData[0].addressline1}`;
            this.form.address_line_2 = `${addressData[0].addressline2 || ''}`;            
            this.form.city = `${addressData[0].county || ''}`; 
          } else {
            this.warnings = ["No address found for the given postcode. Please enter the address manually."];
            this.form.address_line_1 = "";
            this.form.address_line_2 = "";
            this.form.city = "";
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error);
          this.warnings = ["Error fetching address. Please enter the address manually."];
          this.form.address_line_1 = ""; 
          this.form.address_line_2 = "";
          this.form.city = "";
        });
    },
    retrieveFullAddress(id, input, country) {
      this.store
        .dispatch("Admin/RetrieveFullAddress", { id, input, country })
        .then((response) => {
          const addressData = response;
          if (addressData && addressData.length > 0) {
            this.warnings = [];
            this.form.address_line_1 = `${addressData[0].addressline1}`;
            this.form.address_line_2 = `${addressData[0].addressline2 || ''}`;            
            this.form.city = `${addressData[0].county || ''}`; 
            this.form.post_code = addressData[0].postcode; 
          } else {
            this.warnings = ["No full address found for the selected suggestion."];
            this.form.address_line_1 = "";
            this.form.address_line_2 = "";
            this.form.city = "";
          }
        })
        .catch((error) => {
          console.error("Error retrieving full address:", error);
          this.warnings = ["Error retrieving full address. Please enter the address manually."];
          this.form.address_line_1 = "";
          this.form.address_line_2 = "";
          this.form.city = "";
        });
    },
    hideSuggestions() {
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },
    validateCountry() {
      if (this.form.country !== "N/A" && !this.countryOptions.map(option => option.toLowerCase().trim()).includes(this.form.country.toLowerCase().trim())) {
        this.form.country = "N/A";  
        this.warnings = ["The selected country is not valid. Please choose a country from the list."];
      }
    },
  },
  mounted() {
    this.validateCountry();
  },
};
</script>

<style>
.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
}

.warning {
  height: auto;
  width: 100%;
  background: #eb8146;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #ffb248;
}

.success {
  height: auto;
  width: 100%;
  background: #04a394;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #069486;
}

.suggestions {
  list-style: none;
  padding: 0;
  margin: 5px 0 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background: #fff;
}

.suggestions li {
  padding: 5px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #f0f0f0;
}
</style>