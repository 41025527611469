<template>
    <div class="restricted-message">
      <h1>Access Restricted</h1>
      <p>Your business is currently archived. Please contact support for more information.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "RestrictedMessage"
  };
  </script>
  
  <style scoped>
  .restricted-message {
    text-align: center;
    margin-top: 100px;
  }
  </style>
  