<template>
  <div>
    <div class="row">
      <div class="col-5">
        <form v-on:submit.prevent="GetDataSearch" class="form">
          <label for="email">Filter tests.</label>
          <input
            type="text"
            class="input"
            placeholder="Sample ID, UUID, Bucket File..."
            v-model="search"
          />
        </form>
      </div>
      <div class="col-5">
        <form class="form">
          <label>Filter by Business</label>
          <div class="multiselect-placeholder">
            <div class="multiselect-btn" v-on:click="OpenDropdown()">
              Filter by businesses
              <span class="badge" v-if="businesses.length > 0">
                {{ businesses.length }}
              </span>
            </div>
            <div class="options" id="options">
              <div class="searchbox">
                <input
                  type="text"
                  class="input"
                  placeholder="Search Businesses"
                  v-model="this.searchBusiness"
                  v-on:keyup="filterBusinesses()"
                />
              </div>
              <div
                class="option"
                v-for="business in businesslist"
                :key="business.id"
                v-on:click="SelectBusinessFilter(business.id)"
                v-bind:class="
                  businesses.includes(business.id) ? 'selected' : ''
                "
              >
                {{ business.business }}
                <span class="silent" v-if="business.organisation">{{
                  business.organisation
                }}</span>
                <span class="silent" v-else>Independant</span>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-2">
        <div class="btn btn-green" v-on:click="GetDataSearch">Search</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table>
          <thead>
            <tr>
              <td
                v-for="column in columns"
                :key="column.name"
                @click="column.sortable === true ? sortBy(column.name) : ''"
                :class="
                  sortKey === column.name
                    ? sortOrders[column.name] > 0
                      ? 'headerSortUp'
                      : 'headerSortDown'
                    : ''
                "
                :style="'width:' + column.width + ';' + 'cursor:pointer;'"
              >
                {{ column.label }}
              </td>
              <td><i class="icon icon-cogs"></i></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in tblrows"
              :key="row.id"
              v-bind:class="tests.includes(row.id) ? 'selected' : ''"
            >
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ row.id }}
                <!-- {{ getuniqueID(row.test_type,row.id,row.businesscountry) }} -->
                <span
                  class="icon icon-warning"
                  v-if="!row.reviewed"
                  style="color: red"
                ></span>
              </td>

              <td v-on:click="addToTestsList(row.id, row.status)">
                <!-- {{ row.id }} -->
                {{ row.uuid }}
                <!-- Coming Soon -->
                <span
                  class="icon icon-warning"
                  v-if="!row.reviewed"
                  style="color: red"
                ></span>
              </td>
              
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ GetTestype(row)}}
              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ row.email }}

              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ row.business }}
              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ row.cow_id }}
              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{
                  row.client_name !== null && row.client_name !== "null"
                    ? row.client_name
                    : row.farmer_name
                }}
              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ row.tester_name }}
              </td>
              <td
                v-on:click="addToTestsList(row.id, row.status)"
                v-if="row.status == 'green'"
              >
                <span class="icon icon-heart" style="color: #04a394"></span>
              </td>
              <td
                v-on:click="addToTestsList(row.id, row.status)"
                v-if="row.status == 'red'"
              >
                <span class="icon icon-warning" style="color: #d50014"></span>
              </td>
              <td
                v-on:click="addToTestsList(row.id, row.status)"
                v-if="row.status == 'failed'"
              >
                <span class="icon icon-warning" style="color: grey"></span>
                FAILED
              </td>
              <td
                v-on:click="addToTestsList(row.id, row.status)"
                v-if="row.status == 'process'"
              >
                <span class="icon icon-heart"></span>
              </td>
              <td v-on:click="addToTestsList(row.id, row.status)">
                {{ TimeParse(row.created_at) }}
              </td>
              <td>
                <router-link
                  :to="{ name: 'Admin_Tests_Modify', params: { id: row.id } }"
                  class="btn btn-orange"
                  >View / Modify</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        Displaying {{ count }} of {{ total_filter }}. Total of {{ total }} rows.
      </div>
      <div class="col-6">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetDataPrev">
            &larr; PREVIOUS
          </div>
          <div class="btn btn-green" v-on:click="GetDataNext">NEXT &rarr;</div>
        </div>

        <!-- <div
          class="btn btn-green"
          v-if="tests.length > 0"
          v-on:click="DownloadTests"
        >
          <span class="icon icon-cloud-download"></span> DOWNLOAD TESTS -
          {{ tests.length }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Admin_Tests_ListDatatable",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "auto", label: "ID", name: "analysis_test.id", sortable: true },
      { width: "auto", label: "Test ID", name: "analysis_test.id", sortable: true },
      {width: "auto", label: "Test Type", name: "analysis_test.test_type", sortable: true },
      {
        width: "auto",
        label: "Auth User",
        name: "users_user.email",
        sortable: true,
      },
      {
        width: "auto",
        label: "Business",
        name: "analysis_test.business_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Sample ID",
        name: "analysis_test.cow_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Client Name",
        name: "analysis_test.client_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Test Performed by",
        name: "analysis_test.tester_name",
        sortable: true,
      },
      {
        width: "auto",
        label: "Status",
        name: "analysis_test.status",
        sortable: true,
      },
      {
        width: "auto",
        label: "Created",
        name: "analysis_test.created_at",
        sortable: true,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });


    return {
      tblrows: [],
      count: 0,
      order: [8, "DESC"],
      search: "",
      offset: 0,
      total: 0,
      limit: 20,
      total_filter: 0,
      next: 0,
      prev: 0,
      columns: columns,
      sortKey: "created_at",
      sortOrders: sortOrders,
      businesslist: [],
      businesslistWhole: [],
      businesses: [],
      expanded: false,
      tests: [],
      searchBusiness: "",
      uniquetestnumber:0,
      finaluniqueval:'',
      
    };
  },

  methods: {
    GetTestype(row){
      if(row.test_type === 3){
        return 'FLUKE';
      }
      else{
        return 'FEC';
      }
    },
    GetData() {
      let body = {
        search: this.search,
        order: this.order,
        limit: this.limit,
        offset: this.offset,
        businesses: this.businesses,
      };
      this.store
        .dispatch("Admin/GetTestsDatatable", body)
        .then((result) => {
          console.log ('Admim/gettestdatatalbe  -- - -',result);
          this.total = result.total;
          this.total_filter = result.total_filter;
          this.tblrows = result.data;
          this.count = this.offset + this.tblrows.length;
          this.prev =
            this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
          this.next =
            this.offset + this.tblrows.length > this.total_filter
              ? this.total_filter
              : this.offset + this.tblrows.length;
        })
        .catch((err) => {
          console.log(err);
          this.total = 0;
          this.total_filter = 0;
          this.tblrows = [];
          this.count = 0;
          this.prev = 0;

          this.next = 0;
        });
    },
    GetDataSearch() {
      this.tblrows = [];
      this.offset = 0;

      var dropdown = document.getElementById("options");
      dropdown.style.display = "none";
      this.expanded = false;

      this.GetData();
    },
    GetDataNext() {
      if (this.next != this.total_filter) {
        console.log(this.next);
        this.offset = this.next;
        this.GetData();
      }
    },
    GetDataPrev() {
      if (this.offset !== 0) {
        this.offset = this.prev;
        this.GetData();
      }
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      let name = this.getIndex(this.columns, "name", key);
      let dir = this.sortOrders[key] > 0 ? "ASC" : "DESC";
      this.order = [name, dir];

      this.GetData();
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    GetBusinessList() {
      this.store
        .dispatch("Admin/GetListOfBusinesses")
        .then((data) => {
          this.businesslist = data;
          this.businesslistWhole = data;
        })
        .catch((err) => {
          console.log(err.messages);
        });
    },
    OpenDropdown() {
      var dropdown = document.getElementById("options");
      if (!this.expanded) {
        dropdown.style.display = "block";
        this.expanded = true;
      } else {
        dropdown.style.display = "none";
        this.expanded = false;
      }
    },
    SelectBusinessFilter(id) {
      if (this.businesses.includes(id)) {
        var index = this.businesses.indexOf(id);
        this.businesses.splice(index, 1);
      } else {
        this.businesses.push(id);
      }
    },
    addToTestsList(id, status) {
      if (status == "green" || status == "red") {
        if (this.tests.includes(id)) {
          var index = this.tests.indexOf(id);
          this.tests.splice(index, 1);
        } else {
          this.tests.push(id);
        }
      }
    },
    async DownloadTests() {
      this.store
        .dispatch("Admin/DownloadTestsData", this.tests)
        .then((result) => {
          var arrays = [];
          result.forEach((rows) => {
            arrays.push({
              Organisation: rows.organisation || "N/A",
              "Business / Practice": rows.business || rows.vet_practice_name,
              "Client Name": rows.client_name,
              Author: rows.username,
              Address: rows.address,
              County: "N/A",
              Postcode: rows.postcode,
              "Sample ID": rows.cow_id,
              "Date of Test": rows.created_at,
              "Strongyle (EPG)": rows.strongyle,
              "Nematodirus (EPG)": rows.nematodirus,
              "Coccidia (OPG)": rows.coccidia,
              "Strongyloides (EPG)": rows.strongyloides,
              "Trichuris (EPG)": rows.trichuris,
              "Monezia (EPG)": rows.monezia,
              "Bucket File": rows.bucket_file,
            });
          });
          const items = arrays;
          const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
          const header = Object.keys(items[0]);
          const csv = [
            header.join(","), // header row first
            ...items.map((row) =>
              header
                .map((fieldName) =>
                  JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')
                )
                .join(",")
            ),
          ].join("\r\n");

          const data = "data:text/csv;charset=utf-8," + csv;
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", "export.csv");
          link.click();
        })
        .catch((err) => {
          alert(err);
        });
    },
    filterBusinesses() {
      if (this.searchBusiness !== "") {
        var filter = (this.businesslistWhole || []).filter((row) =>
          row.business.toLowerCase().includes(this.searchBusiness.toLowerCase())
        );
        this.businesslist = filter;
      } else {
        this.businesslist = this.businesslistWhole;
      }
    },
//     gettestcountnumber(testid,business_id){
//       let body = {
//         id: testid,
//         bussid : business_id
//       };
//       return this.store.dispatch("Admin/GetTestsnumber", body)
//       .then((result)=>{
//         console.log("Inside the function of gettestnumber",result);
//         return result;
//       })    .catch((error) => {
//       console.error("Error in gettestcountnumber", error);
//       throw error; // Rethrow the error to handle it outside the function
//     });

//     },
//     convertBussidToBussId(bussid) {
//   //   if (bussid >= 0 && bussid < 1000) {
//   //   const prefixes = ['A', 'B', 'C', 'D', 'E','F','G','H','I','J','K'];
//   //   let prefixIndex = Math.floor(bussid / 100);
//   //   if (prefixIndex >= 0 && prefixIndex < prefixes.length) {
//   //     if (bussid === prefixIndex * 100) {
//   //       return prefixes[prefixIndex] + '00';
//   //     } else {
//   //       let numberPart = (bussid % 100).toString().padStart(2, '0');
//   //       return prefixes[prefixIndex] + numberPart;
//   //     }
//   //   }
//   // }
//   // return 'Invalid bussid';

//   let combinationCount = 0;
//   const targetCombinationNumber = bussid;
//   for (let num1 = 0; num1 <= 9; num1++) {
//         for (let num2 = 0; num2 <= 9; num2++) {
//            for (let letter = 'A'.charCodeAt(0); letter <= 'Z'.charCodeAt(0); letter++) {
//               for (let position = 1; position <= 3; position++) {
//                 let combination = '';
//                 for (let i = 1; i <= 3; i++) {
//                   if (i === position) {
//                     combination += String.fromCharCode(letter);
//                   } else {
//                     combination += String(i <= 2 ? num1 : num2);
//                   }
//                 }
//                 combinationCount++;

//                 if (combinationCount === targetCombinationNumber) {
//                  // console.log(`The combination is: ${combination}`);
//                   return combination;
//                   // Break out of all loops
//                   // num1 = 10; num2 = 10; num3 = 10; letter = 'Z'.charCodeAt(0); position = 5;
//                 }
//               }
//             }
//           }
//       }


// },
//   converttestidtoUUID(id){
//       let combinationCount = 0;
      
//       if (id > 104000){
//         id = id % 104000;
//       }
//       const targetCombinationNumber = id;
//       for (let num1 = 0; num1 <= 9; num1++) {
//         for (let num2 = 0; num2 <= 9; num2++) {
//           for (let num3 = 0; num3 <= 9; num3++) {
//             for (let letter = 'A'.charCodeAt(0); letter <= 'Z'.charCodeAt(0); letter++) {
//               for (let position = 1; position <= 4; position++) {
//                 let combination = '';
//                 for (let i = 1; i <= 4; i++) {
//                   if (i === position) {
//                     combination += String.fromCharCode(letter);
//                   } else {
//                     combination += String(i <= 3 ? num1 : num2);
//                   }
//                 }
//                 combinationCount++;

//                 if (combinationCount === targetCombinationNumber) {
//                  // console.log(`The combination is: ${combination}`);
//                   return combination;
//                   // Break out of all loops
//                   // num1 = 10; num2 = 10; num3 = 10; letter = 'Z'.charCodeAt(0); position = 5;
//                 }
//               }
//             }
//           }
//         }
//       }

//   }
// ,
//    getuniqueID(testtype,bussid,id){
    
//      // uniquetestnumber = this.gettestcountnumber(id,bussid);
//     //  console.log("When the function is called ",uniquetestnumber);
//     console.log(id);
//       let type = "W"
//       if(testtype==3){
//         type = "F";
//       }
//       let buss_id = this.convertBussidToBussId(bussid);
//       let uuid = this.converttestidtoUUID(parseInt(id));
//       let Year = new Date().getFullYear();
//       //console.log("Calling the return function in console. ",(type + `${buss_id}` +'-'+ `${uuid}` +'-'+ `${Year.toString()}`));
   
//       return (type + `${buss_id}` +'-'+ `${uuid}` +'-'+ `${Year.toString()}`)
//       //const uniqueID = type + `${buss_id}` + '-' + `${uuid}` + '-' + `${Year.toString()}`;
      

//     }

    

  },
  mounted() {
    this.GetData();
    this.GetBusinessList();
  },
  watcher: {
    // searchBusiness() {
    //   this.filterBusinesses();
    // }
  },
};
</script>

<style>
table {
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-weight: 300;
}

th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
  font-weight: normal;
  text-align: center;
}

td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}

table .selected {
  background: rgba(53, 187, 164, 0.7) !important;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

tr:nth-child(even) td {
  border: 1px solid #dddddd;
}

.float-right {
  float: right;
}

.headerSortDown:after,
.headerSortUp:after {
  content: " ";
  position: relative;
  left: 2px;
  border: 8px solid transparent;
}

.headerSortDown:after {
  top: 10px;
  border-top-color: silver;
}

.headerSortUp:after {
  bottom: 15px;
  border-bottom-color: silver;
}

.headerSortDown,
.headerSortUp {
  padding-right: 10px;
}

a + .btn {
  text-decoration: none;
  color: inherit;
}

.multiselect-placeholder {
  max-width: calc(400px);
  width: 100%;
  z-index: 100;
  position: absolute;
}

.multiselect-btn {
  height: auto;
  background: #fff;
  border: 1px solid #c8ced5;
  font-size: 17px;
  border-radius: 10px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  box-sizing: border-box;
  padding: 5px;
}

.multiselect-btn .badge {
  height: auto;
  width: auto;
  padding: 0 5px;
  background: #35bba4;
  border-radius: 5px;
  color: #fff;
  font-size: 17px;
  display: flex;
  align-content: center;
  justify-content: center;
  float: right;
}

.options {
  display: none;
  background: #fff;
  max-height: 200px;
  height: auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 5px;
  margin-top: -6px;
  border-bottom: 1px solid #c8ced5;
  border-left: 1px solid #c8ced5;
  border-right: 1px solid #c8ced5;
  padding-top: 15px;
  border-radius: 0px 0px 10px 10px;
  z-index: 100;
  width: auto;
}

.multiselect-placeholder ::-webkit-scrollbar {
  display: none;
}

.options .option {
  display: block;
  text-align: left;
  height: auto;
  line-height: 20px;
  font-size: 16px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  margin-bottom: 5px;
}

.options .option .silent {
  display: block;
  font-size: 10px;
  color: #4c4c4c;
}

.options .selected {
  border-right: 3px solid #0e836e;
}
</style>
