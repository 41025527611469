import { createApp } from 'vue'
// import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import { store } from './store'
import CKEditor from '@ckeditor/ckeditor5-vue';


router.beforeEach(async (to, from, next) => {
    document.title = `Micron Kit | ${to.meta.title}`
    console.log(`Going to ${to.name} from ${from.name}`);
    const user = store.state["User"].gotinfo;
    if (user == false && localStorage.MicronToken) {
        store.dispatch('User/UserInformation')
    }
    // Check if the user has an archived business
    const hasArchivedBusiness = store.getters["User/hasArchivedBusiness"];

    // Allow user to access the app, but handle restricted content
    if (hasArchivedBusiness) {
        if (to.name === 'Login') {
            next();
        } else if (to.name !== 'Home') {
            next({ name: 'Home' });
        } else {
            next(); // Allow Home with restricted content
        }
    } else {
        next(); // Normal navigation for active businesses
    }
})

createApp(App)
    .use(store)
    .use(router)
    .use(CKEditor)
    .mount('#app')
