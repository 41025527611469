<template>
  <div>
    <Navigation v-bind:Breadcrumb="[{ name: 'Home', route: 'Home' }]" />
    <div class="placeholder">
      <div v-if="isRestricted">
        <RestrictedMessage />
      </div>
      <div v-else>
        <div class="row">
          <div class="col-4">
            <h2 class="center green">Hey, {{ FullName }}</h2>
            <div class="card">
              <div class="text">Last 14 Days</div>
              <Doughnut ref="piechart_totals" :chart-options="pieChartOptions" :chart-data="pieChartData"
                :chart-id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
                :styles="styles" :width="width" :height="height" :arc="arc" />
            </div>
          </div>
          <div class="col-8">
            <RecentTests />
          </div>
        </div>
        <div class="row" v-if="hasBusiness == true">
          <div class="col-12">
            <h4 class="green">My Network</h4>
          </div>
          <div v-for="business in getBusinesses" :key="business.id" class="col-3">
            <div class="card">
              <div v-if="business.logo" class="img">
                <img :src="business.logo" alt="COMPANY NAME" />
              </div>
              <div v-else class="img">
                <img src="@/assets/micron_teal.png" alt="Micron Logo" />
              </div>
              <div class="businessName center">
                <h3 v-if="business.role > 1">
                  <router-link :to="{
                    name: 'Business_Manage',
                    params: { id: business.id },
                  }">{{ business.name }} <i class="icon icon-cogs"></i></router-link>
                </h3>
                <h3 v-else>{{ business.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import RestrictedMessage from "@/components/RestrictedMessage.vue";
import Navigation from "@/components/Navigation.vue";
import RecentTests from "@/components/RecentTests.vue";

import { Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement
);

export default {
  name: "Home",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      pieChartData: {
        labels: ["Processing", "Negative", "Positive"],
        datasets: [
          {
            label: "Total Tests",
            data: [],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 206, 86, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 206, 86, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
      },
    };
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Object,
      default: () => { },
    },
    arc: {
      type: String,
      default: "",
    },
  },
  components: {
    Navigation,
    RestrictedMessage,
    RecentTests,
    Doughnut
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    available_test() {
      return this.store.state["User"].info.available_test;
    },
    avatar() {
      return this.store.state["User"].info.avatar;
    },
    hasBusiness() {
      return this.store.state["User"].business;
    },
    getBusinesses() {
      return this.store.state["User"].businesses;
    },
    isRestricted() {
      return this.store.getters["User/hasArchivedBusiness"];
    },
  },
  methods: {
    SetTotalTestsChart() {
      this.store
        .dispatch("User/GetLast14DaysAnalytics")
        .then(async (result) => {
          var chartTotals = { process: 0, negative: 0, positive: 0, failed: 0 };
          result.data.map((row) => {
            if (row.status == "process") {
              chartTotals.process = row.totals;
            } else if (row.status == "green") {
              chartTotals.negative = row.totals;
            } else if (row.status == "red") {
              chartTotals.positive = row.totals;
            }
          });

          this.pieChartData.datasets[0].data = [
            parseInt(chartTotals.process) || 0,
            parseInt(chartTotals.negative) || 0,
            parseInt(chartTotals.positive) || 0,
          ];
        });
    },
  },
  created() {
    this.SetTotalTestsChart()
  }
};
</script>

<style scoped>
.card {
  width: auto;
  height: auto;
  background: #fff;
  border: 1px solid #efefef;
  display: block;
  border-radius: 10px 10px;
  box-sizing: border-box;
}

.card .img {
  width: 180px;
  height: 180px;
  display: block;
  margin: 0 auto;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  box-shadow: -1px 9px 20px 0px #efefef;
}

.card img {
  max-width: 180px;
  max-height: 180px;
  display: block;
  padding: 20px;
  box-sizing: border-box;
}

.card .address {
  padding: 10px;
  box-sizing: border-box;
  border-top: 1px solid #efefef;
  font-size: 17px;
}

.card .businessName {
  display: block;
  color: #383838;
}

.card .options {
  display: block;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}

.card .options a {
  color: #383838;
  text-decoration: none;
  display: inline-block;
}
</style>